import { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import Iconify from "src/components/iconify";
import { companyEmailsApi } from "src/api/company-emails";
import { utcToZoned } from "src/utils/utcToZoned";
import { useAuth } from "src/hooks/use-auth";
import { Scrollbar } from "src/components/scrollbar";
import { TableSkeleton } from "src/components/table-skeleton";
import { TableNoData } from "src/components/table-empty";
import { CreateEmailDialog } from "./create-email-dialog";
import { SignatureEditDialog } from "./signature-edit-dialog";
import { SignatureViewDialog } from "./signature-view-dialog";
import { DeleteModal } from "src/components/customize/delete-modal";
import { PageNumberSelect } from "src/components/customize/page-number-popover";
import { EditEmailDialog } from "./edit-email-dialog";

export const useCompanyEmails = (id, brandId) => {
  const [emails, setEmails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { user } = useAuth();

  const getEmails = async () => {
    try {
      setIsLoading(true);
      const { emails } = await companyEmailsApi.getCompanyEmails({
        company_id: id,
        internal_brand_id: brandId,
      });
      if (emails?.length) {
        setEmails(emails?.filter(email => {
          const emailAccess = user?.acc?.[`acc_v_company_email_${email?.id}`];
          return emailAccess === undefined || emailAccess;
        }));
      } else {
        setEmails([]);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw new Error(error);
    }
  };

  useEffect(() => {
    getEmails();
  }, [id, brandId]);

  return {
    emails,
    setEmails,
    getEmails,
    isLoading,
  };
};

export const CompanyEmails = ({ brandId }) => {
  const { user, company } = useAuth();
  const { emails, getEmails, setEmails, isLoading } = useCompanyEmails(company?.id, brandId);

  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openSignatureEdit, setOpenSignatureEdit] = useState(false);
  const [openSignatureView, setOpenSignatureView] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [emailToDelete, setEmailToDelete] = useState();
  const [emailToEdit, setEmailToEdit] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(5);

  const DEFAULT_COLUMN = useMemo(() => [
    {
      id: "id",
      label: "ID",
      enabled: true,
    },
    {
      id: "email",
      label: "Email",
      enabled: true,
    },
    {
      id: "signature",
      label: "Email Signature",
      enabled: true,
      render: (row)=>(
        row?.email_signature?.length>0?
        <Button
          startIcon={<Iconify icon="material-symbols:info-outline" />}
          onClick={()=>{
            setEmailToEdit(row);
            setOpenSignatureView(true);
          }}
          color="inherit"
        >
          Signature
        </Button>
      : null),
    },
    {
      id: "created_at",
      label: "Created At",
      enabled: true,
      render: (row) => (
        <Typography variant="body2" sx={{ whiteSpace: "nowrap" }}>
          {utcToZoned(row?.created_at, user?.timezone)}
        </Typography>
      ),
    },
    {
      id: "actions",
      label: "Actions",
      enabled: true,
      render: (row) => (
        <Stack direction="row" alignItems="center">
          <Tooltip title="Edit Email">
            <IconButton 
              color="primary"
              onClick={() => {
              setEmailToEdit(row);
              setOpenEditModal(true);
              }}
            >
              <Iconify icon="uil:pen" />
            </IconButton>
          </Tooltip>

          <Tooltip title="Edit Signature">
            <IconButton 
              color="primary"
              onClick={() => {
              setEmailToEdit(row);
              setOpenSignatureEdit(true);
              }}
            >
              <Iconify icon="carbon:email"/>
            </IconButton>
          </Tooltip>

          <Tooltip title="Delete">
            <IconButton 
              color="primary"
              onClick={() => {
              setEmailToDelete(row?.id);
              setOpenDeleteModal(true);
              }}
            >
              <Iconify icon="mdi:trash-outline" />
            </IconButton>
          </Tooltip>
        </Stack>
      )
    },
  ], []);

  const handleDeleteEmail = async () => {
    try {
      await companyEmailsApi.deleteCompanyEmail(emailToDelete);
      toast.success("Company email successfully deleted");
      setOpenDeleteModal(false);
      setEmailToDelete(null);
      setTimeout(() => {
        getEmails();
      }, 1500);
    } catch (error) {
      setOpenDeleteModal(false);
      throw new Error(error);
    }
  }

  return (
    <Paper sx={{ px: 2, mb: -2, pb: 4, minHeight:"calc(100vh - 360px)"}}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="h5">Company Emails</Typography>
          <Tooltip title="Manage and create company email addresses here. Ensure each email is verified with your SMTP provider. Agents with the necessary access can use these company emails as the sender address for their communications.">
            <IconButton color="primary">
              <Iconify icon="material-symbols:info-outline" />
            </IconButton>
          </Tooltip>
        </Stack>
        <Button variant="contained" onClick={() => setOpenCreateModal(true)}>+ Add</Button>
      </Stack>
      <Stack sx={{ mt: 8 }}>
        <Stack>
          <Scrollbar sx={{ minHeight: 315 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {DEFAULT_COLUMN?.map((item) => (
                    <TableCell key={`${item.id}-header`}>
                      <Typography
                        sx={{
                          fontSize: 14,
                          fontWeight: "600",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item.label}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableSkeleton cellCount={5} rowCount={5} />
                  ) : (emails?.slice(currentPage * perPage, (currentPage * perPage) + perPage)?.map((email) => {
                    return (
                      <TableRow hover key={`${email.id}-email`}>
                        {DEFAULT_COLUMN
                          ?.map((column, index) => (
                            <TableCell
                              key={`${email?.id + index}-row`}
                              sx={{ whiteSpace: "nowrap" }}
                            >
                              {column?.render
                                ? column?.render(email)
                                : email[column?.id]}
                              </TableCell>
                            ))}
                        </TableRow>
                      );
                    })
                  )}
              </TableBody>
            </Table>
            {(emails?.length === 0 && !isLoading) && <TableNoData />}
          </Scrollbar>
          <Divider />
          <Stack sx={{  flexDirection: { md: 'row', xs: 'column' }, gap: 0, justifyContent: 'flex-end', alignItems: { md: 'center', xs: 'start' } }}>
            <PageNumberSelect 
              currentPage={currentPage} 
              totalPage={emails?.length ? Math.ceil(emails?.length/perPage) : 0}
              onUpdate={setCurrentPage}
            />
            <TablePagination
              component="div"
              labelRowsPerPage="Per page"
              count={emails?.length ?? 0}
              page={currentPage ?? 0}
              rowsPerPage={perPage ?? 5}
              onPageChange={(event, index) => setCurrentPage(index)}
              onRowsPerPageChange={(event) =>
                setPerPage(event?.target?.value)
              }
              rowsPerPageOptions={[5, 10, 25]}
            />
          </Stack>
        </Stack>
      </Stack>

      <CreateEmailDialog
        open={openCreateModal}
        onClose={() => setOpenCreateModal(false)}
        brandId={brandId}
        companyId={company?.id}
        onGetEmails={getEmails}
      />

      <EditEmailDialog
        open={openEditModal}
        onClose={() => {
          setOpenEditModal(false);
          setEmailToEdit(null);
        }}
        brandId={brandId}
        companyId={company?.id}
        onGetEmails={getEmails}
        email={emailToEdit}
      />

      <SignatureEditDialog
        open={openSignatureEdit}
        onClose={() => {
          setOpenSignatureEdit(false);
        }}
        brandId={brandId}
        companyId={company?.id}
        setEmails={setEmails}
        onGetEmails={getEmails}
        email={emailToEdit}
      />

      <SignatureViewDialog
        open={openSignatureView}
        onClose={() => {
          setOpenSignatureView(false);
        }}
        email={emailToEdit}
      />

      <DeleteModal
        isOpen={openDeleteModal}
        setIsOpen={() => {
          setOpenDeleteModal(false);
          setEmailToDelete(null);
        }}
        onDelete={handleDeleteEmail}
        title={"Delete email"}
        description={"Are you sure you want to delete this email?"}
      />
    </Paper>
  );
};
